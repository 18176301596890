<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <fill-profile
            ref="enterProfileDetails"
            @profileCreated="profileCreated()"
            @cancelled="cancel()"
          ></fill-profile>
          <b-modal
            id="invitationAccept"
            no-close-on-backdrop
            no-close-on-keyboard
            no-close-on-esc
            hide-footer
            hide-header
            size="lg"
            centered
          >
            <h5 class="text-info" v-if="loading">Please wait...</h5>
            <h5 class="alert alert-danger text-center" v-if="profileRequired">
              Profile has to be created before you can procced. Please try again
            </h5>
            <h5 class="alert alert-danger text-center" v-if="notVerified">
              Your account is not verfied!
            </h5>
            <b-alert variant="danger" show v-if="noAccount"
              >Please Login/SignUp before proceeding</b-alert
            >
            <div v-else>
              <b-alert
                variant="danger"
                show
                v-if="responseErrors.invitation_code"
                >{{ responseErrors.invitation_code[0] }} or link has
                expired!</b-alert
              >
              <b-alert variant="success" show v-if="enrolledSuccess"
                >Successfully Enrolled.</b-alert
              >
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async mounted() {
    await this.$bvModal.show("invitationAccept");
    await this.checkInvitation();
  },
  data() {
    return {
      loading: true,
      loggedInFalse: false,
      noAccount: false,
      enrolledSuccess: false,
      notVerified: false,
      profileRequired: false,
      responseErrors: {},
    };
  },
  methods: {
    profileCreated() {
      const token = localStorage.getItem("token");
      if (token) {
        this.enrollUser();
      }
    },
    async checkInvitation() {
      const token = await localStorage.getItem("token");
      const existingInvitation = await localStorage.getItem("invitation");
      if (existingInvitation) {
        localStorage.removeItem("invitation");
      }
      if (token) {
        await this.enrollUser();
      }
      if (!token) {
        this.loading = !this.loading;
        this.noAccount = !this.noAccount;
        setTimeout(() => {
          const invitationUrl = this.$route.params.code;
          localStorage.setItem("invitation", invitationUrl);
          this.$router.push({ name: "login" });
        }, 4000);
      }
    },
    async enrollUser() {
      const enrollmentCode = {
        invitation_code: this.$route.params.code,
      };
      const url = this.$store.getters.getAPIKey.acceptInvitation;
      axios
        .post(url, enrollmentCode)
        .then((response) => {
          this.loading = !this.loading;
          this.enrolledSuccess = !this.enrolledSuccess;
          setTimeout(() => {
            this.$router.push({ name: "institute" });
          }, 3000);
          response;
        })
        .catch((error) => {
          this.loading = !this.loading;
          if (error.response.data.errors) {
            this.responseErrors = error.response.data.errors;
            if (this.responseErrors.invitation_code) {
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 3000);
            }
          }

          if (error.response.status == 423) {
            this.$refs.enterProfileDetails.checkProfile();
          }

          if (error.response.status == 403) {
            this.notVerified = true;
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 3000);
          }
        });
    },
    cancel() {
      this.loading = false;
      this.profileRequired = true;
      setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 3000);
    },
  },
};
</script>

<style >
#invitationAccept {
  padding: 20px;
  text-align: center;
}
</style>
